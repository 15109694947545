import https from '@/router/https'

//请求商品列表
export function getCommodity(param) {
  return https.fetchGet('/mallapp/goodsspu/getMyshop/userIdStatus', 
  param);
}

//请求销售中的商品数量
export function getCommodityundercarriage(param) {
  return https.fetchGet('/mallapp/goodsspu/getMyshopNumber/userIdStatus', 
  param);
}
//请求已下架的商品数量
export function getCommodityunderNumber(param) {
  return https.fetchGet('/mallapp/goodsspu/getMyshopNumber/userIdStatus', 
  param);
}
//请求小店商品编辑
export function getCommodityShop(param) {
  return https.fetchPost('/mallapp/goodsspu/updateMyshop/id', 
  param);
}

//请求小店商品上下架
export function getCommodityshelf(param) {
  return https.fetchPost('/mallapp/goodsspu/updateMyshopStatus/id', 
  param);
}