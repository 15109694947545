<template>
  <div>
    <div class="commodity-header">
      <div class="header-ones" @click="$router.go(-1)">
        <van-icon name="arrow-left" size="24px" />
      </div>
      <div class="header-twos" slot="title">
         
          <van-button
            round
            type="info"
            :color="isShowgoods== 'true'?'#FF3C00':'#666'"
            style="height:30px"
            @click="ClickOrder(1)"
          >销售中(1)</van-button>
          <van-button
            round
            type="info"
            :color="isShowgoods== 'false'?'#FF3C00':'#666'"
            style="height:30px"
            @click="ClickOrder(2)"
          >已下架(2)</van-button>
       
       <!-- <van-button
       >
         销售中(1)
       </van-button>
       <van-button>
         已下架(2)
       </van-button> -->
        <!-- <span>销售中(2)</span>
        <span>已下架(1)</span> -->
      </div>
    </div>
    <div class="commodity-center" v-for="(item,index) in getCommoditylist.records" :key="index">
      <div class="center-ones">
        <van-card
          :desc="item.createTime"
          :title="item.name"
          :thumb="item.picUrls[0]"
        >
          <template #tags>
            <p class="center-card">
              <span>拿货价:￥{{item.priceDown}}</span>
              <span>销售价：￥{{item.priceDown * 1 + markup}}</span>
            </p>
            <p>赚：￥{{item.priceUp - item.priceDown}}</p>
          </template>
        </van-card>
        <p class="center-shop">
          <van-button color="rgba(255, 60, 0, 1)"  @click="Commoditycompile">编辑商品</van-button>
          <van-button color="rgba(255, 114, 0, 1)" @click="Commodityfluctuate">重新上架</van-button>
        </p>
        <div>
          
        </div>
         <van-action-sheet v-model="show"  title="上架到小店" >
            <div class="content">
            <van-card
              :price="item.priceUp - item.priceDown"
              :title="item.name"
              :thumb="item.picUrls[0]"
            />
             <p>推荐语</p>
             <p class="content-one">
           <van-field
              v-model="sms"
              left
              placeholder="请输入推荐语"
            >
           </van-field>
           <span>0/30</span>
             </p>
           <p class="content-place">加价(%)</p>
            <p class="content-two">
              <span>
            <van-field v-model="value"  type="number" placeholder="" /> 
              </span>
              <span class="contenter" @click="value = 2">加到最高</span>
              <span class="content-tress" @click="value = 0">不加价</span>
            </p>
            <p class="content-margin" @click="Commodityputaway">
               上架到小店
            </p>
           
            </div>
          
         </van-action-sheet>
      </div>
    </div>
    
  </div>
</template>

<script>
import { getCommodity,getCommodityundercarriage,getCommodityShop, getCommodityshelf} from '@/api/commodity/commodity'
export default {
  name: "commodity",

  data() {
    return {
      value:'',
      inputer:'',
      show:false,
      userId:'',
      shelf:'0',
      id:'',
      markup:'',
      getCommoditylist:[], //商品列表
      getCommoditytypeNumber:[], //商品数量
      shopId:'',
      sms: '',
     orderType:1,
    isShowgoods:'true'

    };
  },

  mounted() {


  },
  created(){
 this.userId = this.$route.query.userId
  this.getCommoditytype();//商品列表
  this.getCommodityNumber();//获取商品不同状态的商品数量
 
  
  },
  methods: {
       ClickOrder(int){
         this.orderType=int
          if(int==1)
       {
         this.isShowgoods='true';
       }else{
         this.isShowgoods='false';
       }
      },
    getCommoditytype(){ //获取商品列表
      let params = {userId:this.userId,shelf:this.shelf}
      getCommodity(params).then(res =>{
         console.log('88',res)
        this.getCommoditylist = res.data.data
        this.id = res.data.data.records[0].id
        console.log(this.id)
       
      })
    },
    getCommodityNumber(){ //获取商品不同状态的商品数量
    let params = {userId:this.userId,shelf:this.shelf}
    getCommodityundercarriage(params).then(res =>{
      console.log('12',res)
      this.getCommoditytypeNumber = res.data.data
    })
    },
    Commoditycompile(){ //小店商品编辑
     this.show = true
      let params = {markup:this.markup,id:this.id}
      getCommodityShop(params).then(res =>{
        this.getCommoditytype()
        console.log('113',res)
      })
    },
    // Commodityfluctuate(){ //小店商品下架
    //   let params = {id:this.id,shelf:'2'}
    //    getCommodityshelf(params).then(res =>{
    //      console.log('0',res)
    //    })
    // },
    Commodityputaway(){ //小店商品上架
      let params = {id:this.id,shelf:'1'}
      console.log(this.id)
       getCommodityshelf(params).then(res =>{
         console.log('1',res)
       })
    }
  },
  watch: {
    value(val) {
      if(+val>2||+val<0) {
        this.value = 0
      }
    }
  },
  
};
</script>
<style lang="less" scoped>
.content {
    padding: 16px 16px 160px;
    
  }
  .content-one{
      display: flex;
      height: 32px;
      :last-child{
        color: #666;
        line-height: 32px;
      }
    }
  .content-two{
    // border: 1px solid red;
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: space-around;
    padding-top:10px ;
    .contenter{
      margin: 0 10px 0 0;
      width: 76px;
      height: 32px;
      background-color: #FF7200;
      text-align: center;
      line-height: 32px;
    }
    .content-tress{
      width: 76px;
      height: 32px;
      // border: 1px solid red;
      background-color: #FF7200;
      line-height: 32px;
      text-align: center;
    }
  }
  .content-place{
    margin: 15px 0 10px 0;
  }
  .content-margin{
    margin-top: 20px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    color: #fff;
    background: url('../../static/会员升级_slices/矩形.png') no-repeat center center;
  }
.commodity-header {
  height: 58px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  //  border: 1px solid red;
  background-color: rgba(255, 255, 255, 1);
  .header-ones {
    padding: 54px 0 30px 10px;
  }
  .header-twos {
    flex-grow: 1;
    padding: 54px 0 30px 0;
    justify-content: center;
    display: flex;
    color: #000;

  }
}
.commodity-center {
  height: 100%;
  top: 58px;
  position: fixed;
  left: 0;
  right: 0;
  background-color: rgba(246, 246, 246, 1);
  padding: 10px 5px 0 5px;
  .center-ones {
    height: 185px;
    // border: 1px solid red;
    padding: 10px 5px 0 5px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    justify-content: space-between;
    justify-content: center;
    .van-card {
      background-color: rgba(255, 255, 255, 1);
      .center-card {
        display: flex;
        :first-child {
          margin-right: 15px;
        }
        :last-child{
         
          font-size: 12px;
        }
      }
    }

    .center-shop {
      display: flex;
      justify-content: space-between;
      :first-child {
        //  display: block;
        height: 36px;
        border-radius: 18px;
        width: 167px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-size: 14px;
      }
      :last-child {
        border-radius: 18px;
        height: 36px;
        width: 167px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-size: 14px;
      }
    }
  }
}
</style>